





















.es-footer {
  position: relative;
  background-color: #2a2a2a;
  padding: 20px 0;
}
.es-footer .copyright {
  text-align: center;
  color: #808080;
  font-size: 14px;
}

.es-footer .copyright a {
  color: #808080;
}
.es-footer .copyright a:hover {
  color: #fff;
}
.inline-block-box {
  display: inline-flex;
  img {
    margin-right: 2px;
  }
}
.mt-2 {
  margin-top: 2px;
}
