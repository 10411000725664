








































































































.bt-container {
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  padding: 32px 0;
  border-bottom: 1px solid #676767;
}
.es-footer-link {
  background: #2a2a2a;
  //
  .pc-container {
    padding: 0 10px;
  }
  .footer-login-wrap {
    width: 220px;
    min-width: 220px;
    margin-top: 12px;
    padding-right: 36px;
    .footer-logo-img {
      height: 47px;
      width: 153px;
    }
    .slogan {
      font-size: 12px;
      font-weight: 400;
      color: #ababab;
      line-height: 17px;
      margin-top: 16px;
    }
  }

  .footer-mean-wrap {
    display: flex;
    justify-content: space-between;
    width: 440px;
    min-width: 440px;
    .mean-title {
      font-size: 16px;
      font-weight: bolder;
      color: #F7F8F9;
      line-height: 1;
    }
    .mean-children {
      margin-top: 16px;
    }
    .children-item {
      font-size: 12px;
      font-weight: 400;
      color: #ababab;
      text-decoration: none;
      display: block;
      line-height: 1;
      margin-bottom: 8px;
      &:hover,
      &:focus,
      &:active {
        color: #fff;
      }
    }
  }
  .footer-code-wrap {
    display: flex;
    width: 390px;
    min-width: 390px;
    margin-left: 20px;

    .footer-code-img {
      margin-top: 12px;
      height: 90px;
      width: 90px;
    }
    .code-title {
      font-size: 16px;
      font-weight: 400;
      color: #808080;
    }
    .wx-code-wrap {
      margin-left: 40px;
    }
  }
  .footer-media-wrap {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    min-width: 200px;
    padding-bottom: 8px;
    margin-left: 20px;
    height: 136px;
    .media-item {
      // margin-left: 40px;
      text-align: center;
      display: flex;
      align-items: center;
      &:hover,
      &:focus,
      &:active {
        .media-title {
          color: #fff;
        }
        .footer-media-img {
          background-color: #c0c0c0;
        }
      }
    }
    .footer-media-img {
      height: 32px;
      width: 32px;
      border-radius: 50%;
      overflow: hidden;
      background-color: #808080;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      margin-right: 8px;
      &.wb-img {
        background-image: url('../../../../assets/images/footer/icon-wb.png');
      }
      &.zh-img {
        background-image: url('../../../../assets/images/footer/icon-zhihu.png');
      }
      &.tm-img {
        background-image: url('../../../../assets/images/footer/icon-tm.png');
      }
    }
    .media-title {
      font-size: 12px;
      font-weight: 400;
      color: #ababab;
    }
  }
  .friend-link {
    display: flex;
    max-width: 1300px;
    margin: 16px auto 0;
    font-size: 14px;
    color: #808080;
    .footer-link-des {
      min-width: 70px;
      line-height: 1;
    }
    .link-wrap {
      display: flex;
      flex-wrap: wrap;
      // height: 25px;
      overflow: hidden;
    }
    .link {
      color: #ababab;
      text-decoration: none;
      font-size: 12px;
      margin-left: 24px;
      margin-bottom: 9px;
      cursor: pointer;
      &:hover,
      &:focus,
      &:active {
        color: #fff;
      }
    }
  }
}

@media (max-width: 1200px) {
  .is-not-homepage {
    .footer-login-wrap {
      display: none;
    }
  }
}
@media (max-width: 992px) {
  .is-not-homepage {
    .footer-media-wrap {
      display: none;
    }
  }
}
@media (max-width: 767px) {
  .is-not-homepage {
    .footer-code-wrap {
      display: none;
    }
    .friend-link {
      display: none;
    }
  }
}
@media screen and (min-width: 1200px) and (max-width: 1300px) {
  .es-footer-link {
    .bt-container {
      max-width: 1200px;
    }
    .friend-link {
      width: 1200px;
    }
    .footer-mean-wrap {
      width: 340px;
      min-width: 340px;
    }
  }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .es-footer-link.is-not-homepage {
    .bt-container {
      max-width: 992px;
    }
    .friend-link {
      width: 992px;
    }
    .footer-mean-wrap {
      width: 385px;
      min-width: 385px;
    }
    .footer-media-wrap {
      margin-left: 0px;
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  .es-footer-link.is-not-homepage {
    .bt-container {
      max-width: 768px;
      justify-content: space-between;
    }
    .friend-link {
      width: 768px;
    }
    .footer-mean-wrap {
      width: 340px;
      min-width: 340px;
    }
    .footer-code-wrap {
      width: 400px;
      min-width: 400px;
    }
  }
}
@media (max-width: 767px) {
  .es-footer-link.is-not-homepage {
    padding: 20px 0;
    .footer-mean-wrap {
      min-width: 340px;
      width: 340px;
    }
    .bt-container {
      justify-content: center;
      min-width: 375px;
    }
  }

  .is-not-homepage {
    .footer-code-wrap {
      display: none;
    }
    .friend-link {
      display: none;
    }
  }
}
