
























.home-page-footer {
  min-width: 1300px;
}
